<template>
    <admin-layout>
        <h1 class="fw-bold text-center mb-3">{{ $t('Moje kurzy') }}</h1>
        <loader v-if="fetching"></loader>
        <div v-else class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
            <div v-for="course in courses" :key="course.id">
                <course :course="course"></course>
            </div>
        </div>
        <p class="text-center" style="font-size: 1.25rem; margin-top: 2rem; text-align: center" v-if="courses.length === 0 && ! fetching">
            {{ $t('Žiadne kurzy sa nenašli') }}
        </p>
    </admin-layout>
</template>

<script>
import AdminLayout from '../layout/AdminLayout'
import Course from '../component/Course'
import Loader from '@/component/Loader.vue'
import { EventBus } from '@/main'

export default {
    beforeDestroy () {
        EventBus.$off('locale-changed', this.fetchCourses)
    },
    components: { Loader, Course, AdminLayout },
    computed: {
        courses () {
            return this.$store.getters['course/selectCourses']
        },
        fetching () {
            return (this.$store.getters['course/isFetching'])
        },
    },
}
</script>
