<template>
    <div :class="`card h-100 ` + (expired && 'disabled')">
        <div v-if="thumbnail" class="card-img-top">
            <figure class="ratio ratio-4x3 m-0">
                <img :alt="$t('Náhľadový obrázok kurzu') + ' ' + course.title" :src="thumbnail" class="img-fluid">
            </figure>
        </div>
        <div class="card-body d-flex flex-column justify-content-between align-items-start">
            <div class="w-100">
                <h5 class="card-title fw-bold mb-3">{{ course.title }}</h5>
                <p class="card-text mb-4" v-html="course.excerpt"></p>
            </div>
            <div class="w-100">
                <div v-if="! expired" class="d-flex justify-content-between align-items-center">
                    <router-link v-if="! expired"
                                 :to="{name: 'courses.detail', params: {courseId: course.id}}"
                                 class="btn btn-success stretched-link">
                        <i class="bi bi-play-fill"></i>
                    </router-link>
                    <p v-if="expiration" class="m-0">
                        {{ $t('Kurz vyprší') }} {{ expiration }}.
                    </p>
                </div>
                <p v-else class="card-text m-0">
                    {{ $t('Kurz vypršal') }} {{ expiration }}.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { getUnixTime, isAfter, parseISO } from 'date-fns'
import { isNil } from 'ramda'
import { courseExpiration } from '@/util/courseExpiration'

export default {
    beforeDestroy () {
        clearInterval(this.timer)
    },
    computed: {
        accessToken () {
            return this.$store.getters['auth/selectAccessToken']
        },
        expired () {
            const expirationDate = this.course?.expire_at
            
            if (isNil(expirationDate)) return false
            
            return isAfter(this.date, parseISO(expirationDate))
        },
        expiration () {
            return courseExpiration(this.date, parseISO(this.course.expire_at), this.$i18n.locale, this.$store.getters['auth/isAdmin'])
        },
        thumbnail () {
            if (isNil(this.course.thumbnail)) return ''
            
            let version = getUnixTime(parseISO(this.course?.updated_at))
            
            if (isNaN(version)) {
                version = getUnixTime(new Date())
            }
            
            return `${this.course.thumbnail}?token=${this.accessToken}&version=${version}`
        },
    },
    data () {
        return {
            date: new Date(),
            timer: null,
        }
    },
    mounted () {
        this.timer = setInterval(function () {
            this.date = new Date()
        }.bind(this), 1000)
    },
    name: 'Course',
    props: {
        course: Object,
    },
}
</script>

<style lang="scss" scoped>
.card {
    &.disabled {
        opacity: .75;
        cursor: not-allowed;
        
        * {
            pointer-events: none;
        }
    }
    
    img {
        object-fit: cover;
    }
    
    .btn {
        align-items: center;
        display: flex;
        height: 42px;
        justify-content: center;
        width: 42px;
    }
}
</style>
